<template>
  <main class="flex-1">
    <PageHeading>Profile</PageHeading>

    <section class="p-8">
      <FileUploadForm />
    </section>
  </main>
</template>

<script lang="ts">
import {defineComponent} from '@vue/composition-api'
import FileUploadForm from '@/components/templates/forms/FileUploadForm'

export default defineComponent({
  components: {FileUploadForm},
})
</script>
